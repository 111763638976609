// with arties Gallary

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
  drawing: string;
}

export function PainwinList_2024() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
  
  const [selectedYear, setSelectedYear] = useState('2024');
  const [response, setResponse] = useState<SessionDetail[]>([]);
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (Array.isArray(res.data)) {
        setResponse(res.data);
      } else if (res.data && Array.isArray(res.data.results)) {
        setResponse(res.data.results);
      } else {
        setResponse([]);
        // console.warn('Unexpected response format:', res.data);
      }
    } catch (error) {
      // console.log('Error fetching data:', error);
    }
  };

  const handleImageClick = (drawing: string) => {
    setSelectedDrawing(drawing);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
  };

  const megaWinner = response.length > 0 ? response[0] : null;
  const groupA = response.slice(1, 4);
  const groupB = response.slice(4, 7);
  const groupC = response.slice(7, 10);

  const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[] }) => (
    <>
      <h3 className="mt-2 mb-2">{title}</h3>
      <div className="col-md-6 offset-md-3 mb-3 nopd">
        <div className="row">
          {group.map(item => (
            <div className="col-md-4 col-sm-4" key={item.id}>
              <div className="gropbox" onClick={() => handleImageClick(item.drawing)}>
                <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} width="100%" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid page-bgs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pain-win-yrs">
                <div className="win-list-box">
                  
                  <div className="pain-yrs zoombt"><Link to="/painthon_win_list_2021">2021</Link></div>
                  <div className="pain-yrs zoombt"><Link to="/painthon_win_list_2022">2022</Link></div>
                  <div className="pain-yrs zoombt"><Link to="#">2024</Link></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="pain-win-list">
                <div className="mega-win-1st">
                  {megaWinner && (
                    <div onClick={() => handleImageClick(megaWinner.drawing)} style={{ cursor: 'pointer' }}>
                      <div className="win-tit">Winner List</div>
                      <h3 className="mt-3">Mega Winner</h3>
                      <div className="megawin">
                        <img src={`https://assets.etvbalbharat.com/${megaWinner.image}`} alt={megaWinner.name} width="100%" />
                      </div>
                    </div>
                  )}

                  <ImageGroup title="Group A" group={groupA} />
                  <ImageGroup title="Group B" group={groupB} />
                  <ImageGroup title="Group C" group={groupC} />
              <div className="col-md-12">
							<span className="art-gal">
                <a href="/artist_gallery_list_2024">Artist Gallery</a></span>
						</div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying the drawing */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={closeModal}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content paintathon-modal-bg">
              <div className="modal-header modal-he-adj">
                <button type="button" className="close close-bg" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body nopd">
                <div className="drws">
                  {selectedDrawing && (
                    <>
                      <img src={`https://assets.etvbalbharat.com/${selectedDrawing}`} width="100%" alt="Drawing" />
                      <div className="drw-cand-name">{response.find(item => item.drawing === selectedDrawing)?.name}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}










































































































