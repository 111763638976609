import React from 'react';
import { useHistory } from 'react-router-dom';
import { paintathon, conan } from '../../constants/iconImageConstant';
import { nextbutton, buttonImg } from '../../constants/iconImageConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';

export function EventPage() {
  const history = useHistory();

  const handleNavigate = (path: string) => {
    history.push(path);
  };

  return (
    <>
    <Header/>
    <div className='main-container'>
      <main role="main" className="container">
        <div className="col-md-12 mt-3 mb-3 zoom">
          <div className="text-center nxtbtns">
            <a href="/paintathon">
              <img src={nextbutton} alt="btn" width="140" />
            </a>
          </div>
          <img
            src={paintathon}
            alt="paintathon"
            width="100%"
            onClick={() => handleNavigate('/paintathon')}
            className="clickable-image"
          />
        </div>
        <div className="col-md-12 mb-3 zoom" >
          <div className="text-center nxtbtns">
            <a href="/canonYears">
              <img src={buttonImg} alt="btn" width="120" />
            </a>
          </div>
          <a href="/canonYears">
            <img
              src={conan}
              alt="conan"
              width="100%"
              onClick={() => handleNavigate('/canonYears')}
              className="clickable-image"
            />
          </a>
        </div>
      </main>
     
    </div>
    <Footer />
    </>
  );
}


