import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { nextpage, nextbutton } from '../../constants/iconImageConstant';

export function PaintathonNextPage() {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <img src={nextpage} width="100%" alt="paintathon" />
          <div className="pain-btn">
            <Link
              to={ROUTER_URL_CONSTANT.PAINT_WIN_LIST_2021}
              className="link-next"
            >
              <img src={nextbutton} alt="paintathon-btn" width="160" />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
