import React from 'react';
import { useHistory } from 'react-router-dom';
import { mob_Painthon, mob_Conan,
mob_nextButton,buttonImg} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';

export function MobEventPage() {
  const history = useHistory();

  const handleNavigate = (path: string) => {
    history.push(path);
  };

  return (
    <div>
      <Header />
      <main role="main" className="container">
        <div className="col-md-12 mt-5 mb-3 zoom">
          <div className="nxpgbtn-pain">
            <a onClick={() => handleNavigate('/mobpaintathon')}>
              <img src={mob_nextButton} alt="paint-btn" width="100" className="clickable-image" />
            </a>
          </div>
          <img
            src={mob_Painthon}
            alt="paintathon"
            className="img-fluid"
            width="100%"
            onClick={() => handleNavigate('/mobpaintathon')}
          />
        </div>
        <div className="col-md-12 zoom">
          <div className="nxpgbtn-conan">
            <a onClick={() => handleNavigate('/mob_canon_winner_year')}>
              <img src={buttonImg} alt="conan-btn" width="85" className="clickable-image" />
            </a>
          </div>
          <a onClick={() => handleNavigate('/mob_canon_winner_year')}>
            <img
              src={mob_Conan}
              alt="conan"
              className="img-fluid clickable-image"
              width="100%"
            />
          </a>
        </div>
      </main>
      <Footer />
    </div>
  );
}
















