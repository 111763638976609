import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import {
  conanyear,
  conanbtn,
  volumeIcon,
  muteIcon,
} from '../../constants/iconImageConstant';

export function CanonYear() {
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handleMuteToggle = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <>
      <Header />
      <audio id="myAudio" autoPlay ref={audioRef}>
        <source
          src="https://assets.etvbalbharat.com/video/DCmusic.mp3"
          type="audio/mp3"
        />
      </audio>

      <button className="audio" id="muteButton" onClick={handleMuteToggle}>
        <img
          id="muteIcon"
          src={isMuted ? muteIcon : volumeIcon}
          alt="Mute/Unmute Icon"
        />
      </button>

      <div className="container-fluid">
        <div className="row">
          <div className="conan-bgs">
            <img src={conanyear} alt="conan" width="60%" />
            <div className="conan-btn">
              <Link
                to={ROUTER_URL_CONSTANT.CANON_WINNER_LIST}
                className="link-next"
              >
                <img src={conanbtn} alt="canon-btn" width="160px" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
