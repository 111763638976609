import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import {
  mob_paintathon_2021,
  mob_paintathon_2022,
  mob_paintathon_2024,
  mob_pain_winner,
  mobWinner,
} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Link } from 'react-router-dom';
import { Footer } from '../../appFooter/Footer';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
  drawing: string;
}

export function MobPainwinList2021() {
  const [response, setResponse] = useState<SessionDetail[]>([]);
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [selectedDrawingName, setSelectedDrawingName] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [yearData, setYearData] = useState<{ [year: string]: SessionDetail[] }>({});
  const [currentYear, setCurrentYear] = useState<string | null>(null);

  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  const fetchPaintathonResult = useCallback(async (year: string) => {
    if (yearData[year]) {
      setResponse(yearData[year]);
      return;
    }

    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const results = Array.isArray(res.data)
        ? res.data
        : res.data?.results || [];
      setYearData((prevData) => ({ ...prevData, [year]: results }));
      setResponse(results);
    } catch (error) {
      // console.log('Error fetching data:', error);
    }
  }, [yearData, url]);

  useEffect(() => {
    if (currentYear) {
      fetchPaintathonResult(currentYear);
    }
  }, [currentYear, fetchPaintathonResult]);

  const handleYearSelect = (year: string) => {
    setCurrentYear(year);
  };

  const handleClick = (drawing: string, name: string) => {
    setSelectedDrawing(drawing);
    setSelectedDrawingName(name);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
    setSelectedDrawingName(null);
  };

  const groupA = response.slice(0, 3);
  const groupB = response.slice(3, 6);

  const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[]; }) => (
    <>
      <h1>{title}</h1>
      <div className="win-container">
        <ul>
          {group.map((item) => (
            <li key={item.id} onClick={() => handleClick(item.drawing, item.name)}>
              <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        {currentYear === null ? (
          <div className="col-md-12">
            <div className="mob-pain-winyrs">
              <img src={mobWinner} alt="Paintathon" width="100%" />
              <div className="mob-pain-yrs">
                <ul>
                  <li onClick={() => handleYearSelect('2021')}>
                    <img src={mob_paintathon_2021} alt="Paintathon 2021" />
                  </li>
                  <li>
                    <Link to="/mob_painthon_win_list_2022">
                      <img src={mob_paintathon_2022} alt="Paintathon 2022" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/mob_painthon_win_list_2024">
                      <img src={mob_paintathon_2024} alt="Paintathon 2024" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="mob-pain-yrs21">
              <div className="yer-2021">
                <img src={mob_pain_winner} alt={`Paintathon ${currentYear}`} width="100%" />
              </div>

              <div className="pain-winlist-box">
                <div className="col-md-12 ">
                  <span className="winlis-tit">Winner List</span>
                </div>

                {groupA.length > 0 && (
                  <ImageGroup title="Group A" group={groupA} />
                )}
                {groupB.length > 0 && (
                  <ImageGroup title="Group B" group={groupB} />
                )}

                <div className="col-md-12">
                  <div className="artgal">
                    <Link to="/mob_artist_gallerylist_2021">Artist Gallery</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Modal for Drawing */}
        {showModal && (
          <div className="modal fade show" style={{ display: 'block' }} onClick={closeModal}>
            <div className="modal-dialog" role="document">
              <div className="modal-content new-body">
                <div className="modal-header new-mod-head">
                  <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    {selectedDrawing && (
                      <>
                        <img src={`https://assets.etvbalbharat.com/${selectedDrawing}`} width="100%" alt="Drawing" />
                        {selectedDrawingName && (
                          <div className="drw-cand-name">{selectedDrawingName}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}


































































































































